import React, {useEffect, useRef, useState} from "react";
import {NavigateFunction, useNavigate, useParams} from "react-router-dom";
import {useReactToPrint} from 'react-to-print';
import {useAuth} from "context/AuthContext";
import {isEmptyString} from "utils/HSUtils";
import DocumentHSAIDA from "../../data/hsaida/DocumentHSAIDA";

import {
    STATUS_COMPLETE,
    STATUS_FINAL,
    STATUS_REJECT,
    STATUS_REPORT,
    STATUS_SAVE
} from "../../data/ApprovalDocumentBase";
import {
    DOC_WINDOW_WIDTH,
    URL_API_APPROVAL_ACTION_APPROVAL, URL_API_APPROVAL_ACTION_CANCEL,
    URL_API_APPROVAL_ACTION_FINAL, URL_API_APPROVAL_ACTION_HOLD,
    URL_API_APPROVAL_ACTION_REJECT,
    URL_API_APPROVAL_ACTION_REPORT,
    URL_API_APPROVAL_DOCU, URL_API_APPROVAL_EXEC_TIME, URL_API_APPROVAL_REF_USER,
    URL_APPROVAL_FORM,
    URL_APPROVAL_VIEW
} from "../../Define";
import {API_RESULT_SUCCESS, APIData, SendValueAsync} from "API";
import axios from "axios";
import {
    STATUS_INFO_AVAILABLE, STATUS_INFO_HIDDEN
} from "../../data/ApprovalInfo";
import ApprovalDocument from "../../data/ApprovalDocument";
import PasswordInputModal from "common/control/PasswordInputModal";
import {DocumentViewer} from "../../component/DocumentViewerControl";
import AuthData from "Auth";
import UserSelectModal from "common/control/selector/UserSelectModal";
import UserDataBase from "data/API/User/UserDataBase";
import DateTimeControl from "common/control/DateTimeControl";
import ApprovalStatusProgress from "../../component/ApprovalStatusProgress";
import FileAttachControl from "common/control/FileAttachControl";
import {
    calcHeight,
    DefaultUserImage,
    getAxiosAuthHeader,
    isShowMenu,
    onBackClick,
} from "Define";
import ChatControl, { ChatData } from "common/control/ChatControl";
import {useMenuUI} from "context/MenuUIContext";
//import Avatar from "react-avatar";
import ApprovalStatus, {APPROVER_KIND_APPROVAL} from "../../data/ApprovalStatus";
import CommentControl, {CommentItem} from "common/control/CommentControl";
import {getParam, joinEx} from "@hskernel/hs-utils";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import styles from './DocumentViewPage.module.css';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { IconFileDownload, IconDots, IconArrowLeft} from '@tabler/icons-react';
import ApprovalPrintLogData from "modules/approval/data/ApprovalPrintLogData";
import {DisconnectNotiFirebase, DisconnectNotiSignalRAsync} from "App";
import DocumentPrintButton from "../../component/DocumentPrintButton";
import {isWVMode} from "lib/RNMessage/RNMessageWV";
import ImageViewerControl from "common/control/ImageViewerControl";
import qs from "qs";
//import RNDataNavigate from "lib/RNMessage/Data/RNDataNavigate";

/**
 * 문서를 엽니다
 * @param {string} DocumentID 문서 ID 입니다
 * @param {string | null} refDoc 참조할 문서 ID 입니다
 * @param {boolean} replaceURL true 면 기존창에서 URL replace 만, false 면 새 창으로 엽니다
 * @constructor
 */
export function DocumentViewOpen(DocumentID: string, refDoc?: string | null, replaceURL?: boolean)
{
    const PARAM_NAME = 'refDoc';
    console.log(`DocID: ${DocumentID} / RefDoc: ${refDoc}`);

    let url = `${URL_APPROVAL_VIEW}/${DocumentID}?window=${replaceURL ? 'false' : 'true'}`;
    const parse = qs.parse(location.search, {ignoreQueryPrefix: true})[PARAM_NAME];
    if (parse != null)
    {
        const parse_string = Array.isArray(parse) ? qs.stringify({refDoc: parse}, { arrayFormat: 'repeat' }) : `${PARAM_NAME}=${parse}`;
        url += `&${parse_string}`;
    }
    if (refDoc != null) url += `&${PARAM_NAME}=${refDoc}`;

    if (replaceURL)
    {
        //Navigate(url, {replace: true});
        location.replace(url);
    }
    else
    {
        if (isWVMode())
        {
            location.href = url;
            //const natigate: RNDataNavigate = {url: url, open: "normal"}
            //sendRNData({type: "NAVIGATE", data: natigate});
        }
        else window.open(url, '_blank', `width=${DOC_WINDOW_WIDTH},height=${calcHeight()}`);
    }
}

const DocumentViewPage = () => {
    const modal = useRef<UserSelectModal>(null);

    const {auth} = useAuth();
    const {menuUI, setMenuUI} = useMenuUI();
    const [doc, setDoc] = useState<DocumentHSAIDA | null>(null);
    const {documentID} = useParams();
    const [widthSize, setWidthSize] = useState<number>(window.innerWidth-90);
    const [modalOpen, setModalOpen] = useState(false);
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const navigate = useNavigate();

    const viewRef = useRef<HTMLDivElement | null>(null);
    const getViewRef = () => viewRef.current;

    const getIsMobile = () => isWVMode() || window.innerWidth <= 1024;
    const [isMobile, setIsMobile] = useState(getIsMobile());
    const [optionOpen, setOptionOpen] = useState(false);

    const onWindowResize = useWindowResize();
    onWindowResize(() =>
    {
        setIsMobile(getIsMobile());
    });
    /*
    const [DocCanvas, setDocCanvas] = useState<HTMLCanvasElement | null>(null);
    const [busyCanvas, setBusyCanvas] = useState(false);
    const [showCanvas, setShowCanvas] = useState(false);

    const [width, setWidth] = useState<number>(window.innerWidth);
    const [height, setHeight] = useState<number>(window.innerHeight);
    useEffect(() =>
    {
        if(DocCanvas != null)
        {
            const downloadLink = document.createElement("a");
            downloadLink.download = "filename.png";
            downloadLink.href = DocCanvas.toDataURL();
            downloadLink.click();
        }
    }, [DocCanvas]);
     */

    const customToPrint = async (printWindow: HTMLIFrameElement) => {
        console.log(printWindow)
        const printContent = printWindow.contentDocument || printWindow.contentWindow?.document;
        if (printContent != null)
        {
            const printedScrollContainer = printContent.querySelector('.scroll-container');

            const originScrollContainer = document.querySelector('.scroll-container');

            // Set the scroll position of the printed container to match the origin container
            //printedScrollContainer.scrollTop = originScrollContainer.scrollTop;

            // You can also set the `overflow` and `height` properties of the printed container to show all content.
            // printedScrollContainer.style.overflow = "visible";
            // printedScrollContainer.style.height = "fit-content";

            if (printWindow.contentWindow != null) printWindow.contentWindow.print();
        }
    }
    const handlePrint = useReactToPrint({
        //content: getViewRef,
        documentTitle: doc?.DocumentID,
        print: customToPrint,
        pageStyle: "table { page-break-inside: auto } tr { page-break-inside: avoid; page-break-after: auto; } thead { display: table-header-group; } tfoot { display: table-footer-group; }"
                   //+"@page { size: auto;  margin: 20mm 0 10mm 0; } body { margin: 0; padding: 0; }"
    });

    async function doPrintAsync()
    {
        if (doc != null && doc.DocumentID != null)
        {
            const isSuccess = await ApprovalPrintLogData.AddListAsync(doc.DocumentID);
            if(isSuccess) handlePrint(getViewRef);
        }
    }


    useEffect(() =>
    {
        if(!isEmptyString(documentID))
        {
            if(isShowMenu())
            {
                if(!menuUI.hideLocate) setMenuUI({ hideLocate: true });
            }
            else
            {
                if(!menuUI.hideAll) { setMenuUI({hideAll: true}); return; }

                if(!isWVMode())
                {
                    //알림 해제
                    DisconnectNotiFirebase().then();
                    DisconnectNotiSignalRAsync().then();
                }
            }

            onRefresh();
        }

    }, [documentID]);




    const onRefresh = () =>
    {
        setDoc(null);

        const refDocID = Array.isArray(query.refDocID) ?
            // @ts-ignore
            encodeURIComponent(joinEx(query.refDocID, ',')):
            //qs.stringify({aa: query.refDocID}, { arrayFormat: 'comma', skipNulls: true }) :
            query.refDocID as string;

        DocumentHSAIDA.GetDocument(documentID!, true, refDocID, navigate).then((docData) => {
            if(docData != null)
            {
                setDoc(docData!);
                document.title = `결재문서 보기 [${docData!.DocumentID}]`;
            }
        });
    }

    const onRefUser = () => {
        if (modal.current != null && doc != null) modal.current.openDialog(doc.RefUser.map(user => user.Approver));
    }

    const saveRefUser = async (users: UserDataBase[]) => {
        if (doc != null) {
            const user = users.map(user => user.LoginID).join(',');
            const result = await SendValueAsync<APIData>(axios({
                    method: "POST",
                    url: `${URL_API_APPROVAL_REF_USER}/${doc.DocumentID}`,
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: {"users": user}
                }
            ), true);

            if (result != null && result.result == API_RESULT_SUCCESS) onRefresh();
        }
    }

    const getCommentDataAsync = async (isModifyComment: boolean) =>
    {
        const comment: ChatData[] = [];
        if(doc != null && auth != null)
        {
            if(!isEmptyString(doc.DocumentComment))
            {
                const WriterUser = doc.getWriterUser();
                comment.push({
                    bubbleCorn: "LoginID",
                    title: WriterUser.toString(),
                    me: doc.LoginID == auth.user.LoginID,
                    text: doc.DocumentComment,
                    tag: "상신의견",
                    image: <ImageViewerControl src={UserDataBase.getImageThumbURL(WriterUser.LoginID)} defaultImage={DefaultUserImage} alt={doc.WriteName ?? ""}/>,
                    time: doc.ReportDate ?? undefined,
                    data: WriterUser
                })
            }

            doc.Approval.forEach((appr) =>
            {
                const isMe = appr.Approver.LoginID == auth.user.LoginID;
                const isApproval = appr.Kind == APPROVER_KIND_APPROVAL;

                if(isModifyComment)
                {
                    if(appr.Modify != null && appr.ModifyComment != null)
                    {
                        let title = appr.Approver.toString();
                        const isInsteadModify = appr.Modify.LoginID != appr.Approver.LoginID;
                        if(isInsteadModify) title += ` (대결: ${appr.Modify.toString()})`;
                        comment.push({
                            bubbleCorn: "LoginID",
                            title: title,
                            me: isMe,
                            text: appr.ModifyComment,
                            tag: "수정의견",
                            image: <ImageViewerControl src={UserDataBase.getImageThumbURL(appr.Approver.LoginID)} defaultImage={DefaultUserImage} alt={appr.Approver.Name ?? ""}/>,
                            time: appr.ModifyDate ?? undefined,
                            data: appr.Modify
                        });
                    }
                }

                if(appr.ApprovalComment != null)
                {
                    comment.push({
                        bubbleCorn: "LoginID",
                        title: appr.Approver.toString(),
                        me: isMe,
                        text: appr.ApprovalComment,
                        time: appr.ApprovalDate ?? undefined,
                        tag: `${isApproval ? "결재" : "합의"}의견`,
                        image: <ImageViewerControl src={UserDataBase.getImageThumbURL(appr.Approver.LoginID)} defaultImage={DefaultUserImage} alt={appr.Approver.Name ?? ""}/>,
                        //image: <img src={UserData.getImageThumbURL(appr.Approver.LoginID)} alt={appr.Approver.NameKorean ?? ""}/>,
                        //image: <Avatar name={appr.Approver.NameKorean ?? ""} size="32"/>,
                        data: appr.Approver
                    });
                }
            });
        }
        return comment;
    }

    function getComments(Approval: ApprovalStatus[], showModify: boolean)
    {
        const approvals: CommentItem[] = [];

        if(doc != null && !isEmptyString(doc.DocumentComment))
        {
            approvals.push({
                title: doc.getWriterUser().toString(),
                text: doc.DocumentComment,
            })
        }

        Approval.forEach((appr) =>
        {
            if(!isEmptyString(appr.ApprovalComment)) approvals.push({
                title: `${appr.Approver.toString()} (${appr.ApprovalDate == null ? '-' : appr.ApprovalDate.format("yyyy-mm-dd TT hh:MM")})`,
                text: appr.ApprovalComment!,
                //tail: appr.ApprovalDate!.format("yyyy-MM-dd TT hh:mm")
            });
            if(showModify && !isEmptyString(appr.ModifyComment)) approvals.push({
                title: `${appr.Modify!.toString()} (${appr.ModifyDate == null ? '-' : appr.ModifyDate.format("yyyy-mm-dd TT hh:MM")})`,
                text: appr.ModifyComment!,
                //tail: appr.ModifyDate!.format("yyyy-MM-dd TT hh:mm")
            });
        });
        console.log(approvals)
        return approvals;
    }

    function getCommentControl(doc: DocumentHSAIDA | null)
    {
        if(!isMobile && doc != null)
        {
            const comments = getComments(doc.Approval, false);
            if(comments.length > 0) return <><CommentControl title="의견" placeHolder="" items={comments}/><br/></>;
        }
        return "";
    }

    return auth == null || doc == null ? (
        <div>
            <div className="page page-center">
                <div className="container container-slim py-4">
                    <div className="text-center" style={{marginTop: "300px"}}>
                        <div className="text-muted mb-3" style={{fontSize: "16px"}}>문서를 불러오는 중 입니다.<br/>잠시만 기다려주세요.</div>
                        <div className="progress progress-sm">
                            <div className="progress-bar progress-bar-indeterminate"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div>
            <UserSelectModal ref={modal} title="수신및참조 사용자 선택" max={10} auth={auth} onSave={(users) => saveRefUser(users).then()}/>
            <ChatControl title="의견" showBadge={true} showProfile={true} showProfileMe={false} showBubbleCornMe={false} onRefresh={() => getCommentDataAsync(true)}/>
            <div className={(getParam("menu") !== "true" ? styles.approval_nav:styles.pd_10) + " navbar navbar-expand-md d-print-none"}>
                <div className="container-xl">
                    {isMobile && getParam("menu") !== "true" &&
                        <div className="navbar-nav flex-row">
                            <div className={styles.mobile_back} onClick={() => onBackClick(true)}><IconArrowLeft/></div>
                        </div>
                    }
                    <div className={styles.mobile_approval + " navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3"}>
                        <p>문서보기 - {doc.DocumentClassName}</p>
                    </div>
                    <button className={styles.nav_toggle_btn} type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu" aria-controls="navbar-menu" aria-expanded="false" aria-label="Toggle navigation">
                        { isMobile && <IconDots onClick={() => setOptionOpen(!optionOpen)}/>}
                    </button>
                    <div className={styles.pc_approval_Btn + " navbar-nav flex-row"}>
                        <ApprovalButton auth={auth} data={doc} onPrint={doPrintAsync} onRefresh={onRefresh} onRefUser={onRefUser}/>
                    </div>
                </div>
                {optionOpen && <div className={styles.btn_option_list}>
                    <ApprovalButton auth={auth} data={doc} onPrint={doPrintAsync} onRefresh={onRefresh} onRefUser={onRefUser}/>
                </div>}
            </div>
            <br/>
            {getParam("menu") !== "true" && <div className={styles.mTop}/>}
            <div className={styles.mobile_approval_Btn}>
                <ApprovalButton auth={auth} data={doc} onPrint={doPrintAsync} onRefresh={onRefresh} onRefUser={onRefUser}/>
            </div>
            <div className="container-xl">
                {getCommentControl(doc)}
                <div className={`card ${styles.document_view_card}`} style={{overflowX:"scroll"}}>
                    <div className="card-body">
                        <div className="container-xl">
                            {/* initialContent={initialContent} */}
                            <div className={styles.mobile_pc_viewer}>
                                <DocumentViewer ref={viewRef} doc={doc}/>
                            </div>
                            <div className={styles.mobile_m_viewer}>
                                <TransformWrapper minScale={(widthSize)/800} initialScale={(widthSize)/800}>
                                    <TransformComponent wrapperStyle={{width:"100%",height:"100%"}} >
                                        <DocumentViewer ref={viewRef} doc={doc}/>
                                    </TransformComponent>
                                </TransformWrapper>
                            </div>
                            <br/>
                        </div>
                    </div>
                </div>
                <div className={styles.m_file_btn} onClick={()=> setModalOpen(true)}>
                    <span className={styles.file_num}>{doc.Attach.length}</span>
                    <IconFileDownload size={40}/>
                </div>
                <div className={modalOpen? styles.black_bg:styles.approval_attach_none}></div>
                <div className={modalOpen? styles.approval_attach:styles.approval_attach_none} style={{margin: "10px auto"}}>
                    <div className={`card ${styles.attach_wrap}`} style={{marginBottom: "10px"}}>
                        <div className={`card-body ${styles.attach_box}`}>
                            <FileAttachControl attachs={doc.Attach} isViewer={true} authToken={getAxiosAuthHeader()}/>
                        </div>
                        <div className={`card-footer text-end ${styles.file_zone_footer}`}>
                            <button className="btn btn-primary" onClick={()=> setModalOpen(false)}>닫기</button>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
        </div>
    )
}

export default DocumentViewPage

type ApprovalButtonProps = {
    auth: AuthData,
    data: ApprovalDocument,
    onPrint: () => Promise<void>,
    onRefresh: () => void,
    onRefUser: () => void,
}
type ApprovalStatusInfoDialog = {
    docu: ApprovalDocument | null
}
const ApprovalButton = ({auth, data, onPrint, onRefresh, onRefUser}: ApprovalButtonProps) => {
    const MODE_APPR = "appr";
    const MODE_PROGRESS = "progress";
    const MODE_PROGRESS_C = "progress_c";
    const MODE_HOLD = "hold";
    const MODE_REJECT = "reject";
    const MODE_FINAL = "final";
    const MODE_CANCEL = "cancel";
    const MODE_VIEW_MODIFY = "view_modify";

    const navigate = useNavigate();
    const pwDialog = useRef<PasswordInputModal>(null);
    const [buttonMode, setButtonMode] = useState<string | null>(null);
    const [busy, setBusy] = useState(false);

    const [approvalStateOpen, setApprovalStateOpen] = useState<ApprovalStatusInfoDialog>({docu: null});

    const onSend = async (password: string, comment: string | null, url: string) =>
    {
        setBusy(true);
        try
        {
            return await SendValueAsync<APIData>(axios({
                    method: "POST",
                    url: url,
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: {"Password": password, "Comment": comment}
                }
            ), false);
        }
        catch {  return null;  }
        finally { setBusy(false); }
    }

    const resultProcess = (result: APIData | null, close: boolean, success: (result: APIData) => void) =>
    {
        if (result != null && result.result == API_RESULT_SUCCESS)
        {
            if (window.parent != null) window.parent.location.reload();
            if (window.opener != null) window.opener.location.reload();

            if(close)
            {
                window.close();

                if(window.opener == null)
                {
                    //Chrome Browser 용 Close
                    window.postMessage({ type: "HS_APPLICATION/CLOSE" }, "*");
                }

                //history.back();
            }
            success(result);

            return true;
        }

        //if (pwDialog.current != null) pwDialog.current.openDialog(false);
        return false;
    }
    const action = async (password: string, comment: string | null) => {
        if (buttonMode == MODE_PROGRESS ||
            buttonMode == MODE_PROGRESS_C) return resultProcess(await onSend(password, comment, `${URL_API_APPROVAL_ACTION_REPORT}/${data.DocumentID}?IsCancel=${buttonMode == MODE_PROGRESS_C}`), buttonMode == MODE_PROGRESS, (result) => DocumentViewOpen(result.data[0], null, true));
        else if (buttonMode == MODE_REJECT) return resultProcess(await onSend(password, comment, `${URL_API_APPROVAL_ACTION_REJECT}/${data.DocumentID}`), false, () => onRefresh());
        else if (buttonMode == MODE_HOLD) return resultProcess(await onSend(password, comment, `${URL_API_APPROVAL_ACTION_HOLD}/${data.DocumentID}`), false, () => onRefresh());
        else if (buttonMode == MODE_FINAL) return resultProcess(await onSend(password, comment, `${URL_API_APPROVAL_ACTION_FINAL}/${data.DocumentID}`), false, () => onRefresh());
        else if (buttonMode == MODE_APPR) return resultProcess(await onSend(password, comment, `${URL_API_APPROVAL_ACTION_APPROVAL}/${data.DocumentID}`), true, () => onRefresh());
        else if (buttonMode == MODE_CANCEL) return resultProcess(await onSend(password, comment, `${URL_API_APPROVAL_ACTION_CANCEL}/${data.DocumentID}`), false, () => onRefresh());
        else if (buttonMode == MODE_VIEW_MODIFY) {/*TODO: 수정사항 뷰 구현하기*/}

        return false;
    }

    const deleteDocu = async () => {
        if (confirm("정말로 삭제하시겠습니까?"))
        {
            setBusy(true);
            try
            {
                await axios.delete(`${URL_API_APPROVAL_DOCU}/${data.DocumentID}`);
                alert("삭제 하였습니다");

                onBackClick(true);

                if (window.opener != null) window.opener.location.reload();
            }
            finally { setBusy(false); }
        }
    }

    const execDate = useRef<DateTimeControl>(null);
    const execDocu = () =>
    {
        if(execDate.current != null && execDate.current.getDate() != null)
        {
            setBusy(true)
            SendValueAsync<APIData>(axios({
                method: "POST",
                url: `${URL_API_APPROVAL_EXEC_TIME}/${data.DocumentID}`,
                headers: {'content-type': 'application/x-www-form-urlencoded'},
                data: {"datetime": execDate.current.getDateString()}
            }), false)
                .then(() => onRefresh())
                .finally(() => setBusy(false));
        }
        else alert("집행일을 지정해 주세요!!");
    }

    const btnAction = (e: React.MouseEvent<HTMLButtonElement>, btnMode: string, msg: string) => {
        if (confirm(msg)) {
            e.currentTarget.blur();
            setButtonMode(btnMode);
            if (pwDialog.current != null) pwDialog.current.openDialog(true);
        }
    }

    /**
     *
     * @param {string} URL
     * @param {boolean | null} IsApproval
     * @param {string | null} DocumentID 복사할 문서번호
     */
    function gotoWrite(URL: string, IsApproval: boolean | null, DocumentID?: string | null)
    {
        const param: string[] = [];
        if(getParam("menu") == "true") param.push("menu=true");
        if(IsApproval != null) param.push(`approval=${IsApproval}`);
        if(DocumentID != null) param.push(`doc=${DocumentID}`);
        navigate(`${URL}?${param.join('&')}`);
    }
    //문서가 반려 상태 일때는 수정 버튼만 활성화
    return (
        <>
            <ApprovalStatusProgress isOpen={approvalStateOpen.docu != null} doc={approvalStateOpen.docu} approvals={data.Approval} onClose={() => setApprovalStateOpen({docu: null})}/>
            <PasswordInputModal ref={pwDialog} showPassword={auth.setting.SimplePWUse} showComment={true} onOK={action}/>
            <div className={styles.btn_wrap}>
                {(data.DocumentStatus === STATUS_REJECT || data.DocumentStatus === STATUS_FINAL || data.DocumentStatus === STATUS_COMPLETE) && data.LoginID == auth.user.LoginID ?
                    <button className={styles.mobile_none + " btn"} disabled={busy} onClick={() => gotoWrite(`${URL_APPROVAL_FORM}/${data.DocumentClass}`, null, data.DocumentID)}>재작성</button> : ""
                }
                {(data.DocumentStatus === STATUS_SAVE || data.DocumentStatus == STATUS_REPORT) && data.LoginID == auth.user.LoginID ?
                    <button className="btn" disabled={busy} onClick={(e) => {
                        //window.postMessage({ type: "HS_APPLICATION/CLOSE" }, "*");
                        //return;
                        if (data.DocumentStatus === STATUS_SAVE)
                        {
                            if(data.Approval.length > 0) btnAction(e, MODE_PROGRESS, "상신 하시겠습니까?\n상신후에는 수정/삭제할 수 없습니다");
                            else alert("결재라인이 비어있을때는 상신할 수 없습니다\n수정 버튼을 눌러 결재라인을 등록해 주세요");
                        }
                        else {
                            if (data.RefDocument.length == 0) btnAction(e, MODE_PROGRESS_C, "상신을 취소하시겠습니까?");
                            else alert("다른 문서에서 참조하고 있어 상신취소할 수 없습니다\n └ " + data.RefDocument.join("\n └ "));
                        }
                    }}>{data.DocumentStatus === STATUS_SAVE ? "상신" : "상신취소"}</button> : ""
                }
                {data.DocumentStatus === STATUS_SAVE ?
                    <>
                        <button className={styles.mobile_none + " btn"} disabled={busy || isEmptyString(data.DocumentID)} onClick={() => gotoWrite(`${URL_APPROVAL_FORM}/${data.DocumentID}`, null, null)}>수정</button>
                        <button className={styles.mobile_none + " btn"} disabled={busy || !isEmptyString(data.ERPDocumentID)} onClick={deleteDocu}>삭제</button>
                    </>
                    : ""}
                {data.ApprovalInfo.Executable == STATUS_INFO_AVAILABLE ?
                    <>
                        <DateTimeControl ref={execDate} className={styles.form_control} value={data.ApprovalInfo.ExecTime} />
                        <button className="btn" disabled={busy} onClick={execDocu}>집행</button>
                    </>
                    : ""
                 }
                {data.ApprovalInfo.Approvalable == STATUS_INFO_HIDDEN ? "" :
                    <>
                        <button className={styles.mobile_none + " btn"} disabled={busy || isEmptyString(data.DocumentID)} onClick={() => gotoWrite(`${URL_APPROVAL_FORM}/${data.DocumentID}`, true, null)}>수정</button>
                        <button className="btn" disabled={busy || data.ApprovalInfo.Approvalable != STATUS_INFO_AVAILABLE} onClick={(e) => btnAction(e, MODE_APPR, "결재 하시겠습니까?")}>결재</button>
                    </>}
                {data.ApprovalInfo.Cancelable == STATUS_INFO_HIDDEN ? "" :
                    <button className="btn" disabled={busy || data.ApprovalInfo.Cancelable != STATUS_INFO_AVAILABLE}
                            onClick={(e) => btnAction(e, MODE_CANCEL, "결재를 취소 하시겠습니까?")}>결재취소</button>}
                {/*{data.ApprovalInfo.Finalable == STATUS_INFO_HIDDEN ? "" :
                    <button className="btn" disabled={busy || data.ApprovalInfo.Finalable != STATUS_INFO_AVAILABLE}
                            onClick={(e) => btnAction(e, MODE_FINAL, "전결 하시겠습니까?\n전결 후에는 취소할 수 없습니다")}>전결</button>}*/}
                {data.ApprovalInfo.Holdable == STATUS_INFO_HIDDEN ? "" :
                    <button className="btn" disabled={busy || data.ApprovalInfo.Holdable != STATUS_INFO_AVAILABLE}
                            onClick={(e) => btnAction(e, MODE_HOLD, "보류 하시겠습니까?")}>보류</button>}
                {data.ApprovalInfo.Rejectable == STATUS_INFO_HIDDEN ? "" :
                    <button className="btn" disabled={busy || data.ApprovalInfo.Rejectable != STATUS_INFO_AVAILABLE}
                            onClick={(e) => btnAction(e, MODE_REJECT, "반려 하시겠습니까?\n반려후에는 취소할 수 없습니다")}>반려</button>}

                {data.LoginID == auth.user.LoginID && <button type="button" className="btn" disabled={busy} onClick={onRefUser}>수신및참조</button>}
                <button type="button" className="btn" onClick={() => setApprovalStateOpen({docu: data})}>상태</button>
                {data.DocumentID != null && <DocumentPrintButton documentID={data.DocumentID} onPrint={onPrint}/>}
            </div>
        </>
    )
}

