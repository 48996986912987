import React from 'react';
import {IFileViewerProps} from "./IFileViewer";

// Core viewer
import {SpecialZoomLevel, Viewer, Worker} from '@react-pdf-viewer/core';

// Plugins
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout/lib';

// Locales
import ko_KR from "@react-pdf-viewer/locales/lib/ko_KR.json";

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

//const PDFJS = "//unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js";
const PDFJS = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js";

const PDFViewer = (props: IFileViewerProps) =>
{
    // Create new plugin instance
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const getURL = () => props.fileURL.charAt(1) == "/" ? location.origin + props.fileURL : props.fileURL;

    return (
        <div style={{height:"calc(100vh - 100px)"}}>
            <Worker workerUrl={PDFJS}>
                <Viewer fileUrl={getURL()} defaultScale={SpecialZoomLevel.PageFit} localization={ko_KR}
                        plugins={[
                            // Register plugins
                            defaultLayoutPluginInstance,
                        ]}
                />
            </Worker>
        </div>
    )
}

export default PDFViewer;