import styles from "./TopHeader.module.css";
import iconMoreDark from "./resource/menu-more-dark.png";
import iconMore from "./resource/menu-more.png";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {useTheme} from "../context/ThemeContext";
import ModuleInfo, {AdminMenuKind, checkModuleMenu, getMenuPathID, ModuleMenu} from "../modules/ModuleInfo";
import {GetIcon, GetPath} from "./SideMenu";
import {PathMakerMulti, randomKey} from "@hskernel/hs-utils";
import {Async} from "react-async";
import {getRouteModuleState} from "../Module";
import {useAuth} from "../context/AuthContext";

type SideMenuShortcutProps = {
    module: ModuleInfo,
    menu?: ModuleMenu,
}

type MenuItem = {
    menu: ModuleMenu,
    focus: boolean,
    url: string,
}
type Menus = {
    show: MenuItem[],
    hide: MenuItem[],
}

const SideMenuShortcut = (props: SideMenuShortcutProps) =>
{
    /**
     * 갯수
     */
    const MENU_COUNT = 5;
    const MENU_WIDTH = 75;

    const {theme, setDarkMode} = useTheme();
    const {admin, menuProperty} = useAuth();

    const [dropSwitch,setDropSwitch]  = useState(false);

    function getMenus(): Menus
    {
        const show: MenuItem[] = [];
        const hide: MenuItem[] = [];
        const auto: MenuItem[] = [];
        props.module.category.forEach(category =>
        {
            category.menu.forEach(menu =>
            {
                const pathID = getMenuPathID(category, menu);

                //메뉴 숨김처리 (비활성화)가 안되있으면...
                const isShow = !menu.hide;
                const checkMenu = checkModuleMenu(menuProperty, props.module.id, pathID, false, menu.admin, admin)
                if (isShow && checkMenu)
                {
                    const url = PathMakerMulti(GetPath(props.module.id), category.path ?? '', menu.path);
                    const shortcut = menu.shortcut ? menu.shortcut(admin) : false;
                    const item: MenuItem = {menu: menu, url: url, focus: props.menu == menu};
                    if (shortcut == 'show') show.push(item);
                    else if(shortcut == 'hide') hide.push(item);
                    else if (shortcut == 'auto') auto.push(item);
                }
            })
        });

        for (let i = 0; i < auto.length; i++)
        {
            if (show.length < MENU_COUNT) show.push(auto[i]);
            //0번부터 Insert
            else show.unshift(auto[i]);
        }

        return {show: show, hide: hide};
    }

    function render()
    {
        const menus = getMenus();

        const moduleState = getRouteModuleState(window.location.pathname);

        /**
         *
         * @param item
         * @param i
         * @param num 버튼 갯수
         */
        function getMenuShowButton(item: MenuItem, i: number, num: number)
        {
            if (item.menu == null) return "";
            else
            {
                let isMatch = false;
                if(moduleState.current != null && moduleState.current.Menu != null)
                    isMatch = Object.is(item.menu, moduleState.current.Menu) || item.menu.id == moduleState.current.Menu.id;

                let icon = GetIcon(item.menu, theme.darkMode);
                icon = icon ?? GetIcon(props.module, theme.darkMode);
                //styles.mobile_quick_menu_focus
                return (
                    <Link key={randomKey(i)} className={`${styles.mobile_quick_menu} ${isMatch ? styles.focus : ""}`} to={item.url} style={{width:`${num <= MENU_COUNT && 100/(num+1)}%`}}>
                        <div className={styles.menu_icon}>
                            {icon}
                        </div>
                        <p className={styles.menu_title}>{item.menu.title}</p>
                    </Link>)
            }
        }

        function getMenuHideButton(item: MenuItem)
        {
            let icon = GetIcon(item.menu, theme.darkMode);
            icon = icon ?? GetIcon(props.module, theme.darkMode);

            return (
                <Link to={item.url} className={styles.quick_drop_li}>
                    {icon}
                    <p>{item.menu.title}</p>
                    {/*<span>0</span>*/}
                </Link>
            )
        }

        function getMenuButton(item: MenuItem, i: number)
        {
            return item.menu.onRender == null ? getMenuShowButton(item, i, menus.show.length) :
                <Async key={randomKey(i)} promiseFn={() => Promise.resolve(item.menu.onRender!(item.menu, true, admin))}>
                    <Async.Pending>{getMenuShowButton(item, i, menus.show.length)}</Async.Pending>
                    <Async.Fulfilled>{(menu: ModuleMenu) => getMenuShowButton({...item, menu: menu}, i, menus.show.length)}</Async.Fulfilled>
                </Async>
        }

        return (menus.show.length > 0 || menus.hide.length > 0 ?
            <div className="shortcut_mt">
                <div className={`navbar-expand-md ${styles.navbar_menu} ${styles.quick_header}`}>
                    <div className={styles.nav_over_box} style={{width:`${menus.show.length > MENU_COUNT && MENU_WIDTH * (menus.show.length + 1)}px`}}>
                        {menus.show.map((menu, i) => getMenuButton(menu, i))}
                        {menus.hide.length == 0 ? "" :
                            <div className={styles.mobile_quick_menu} onClick={() => setDropSwitch(!dropSwitch)} style={{width:`${menus.show.length <= 3 && 100/(menus.show.length+1)}%`}}>
                                <div className={styles.menu_icon}>
                                    <img src={theme.darkMode ? iconMoreDark : iconMore}/>
                                </div>
                                <p className={styles.menu_title}>더보기</p>
                            </div>
                        }
                    </div>
                </div>
                {menus.hide.length == 0 ? "" :
                    <div className={styles.quick_drop_menu} style={dropSwitch ? {height:"auto"} : {height:"0"}}>
                        {menus.hide.map(menu => getMenuHideButton(menu))}
                    </div>
                }

            </div> : <></>
        )
    }
    return render();
}

export default SideMenuShortcut;
