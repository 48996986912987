import UserDataSimple from "data/API/User/UserDataSimple";
import LoginData from "./data/API/LoginData";
import CompanyData from "./data/API/CompanyData";
import {
    URL_FRONT_LOGIN,
    URL_FRONT_ERROR,
    URL_API_LOGOUT,
    sendRNData,
    STORAGE_KEY_LOGIN_APP_TOKEN,
    STORAGE_KEY_LOGIN_ID, STORAGE_KEY_COMPANY_ID, unregisterFCMToken
} from "./Define";
import axios, {AxiosError, isAxiosError} from "axios";
import * as Define from "./Define";
import UserSettingsAdminData from "./data/API/User/UserSettingsAdminData";
import {getParam, isEmptyString, PathMaker} from "@hskernel/hs-utils";
import { NavigateFunction, Location } from "react-router";
import {plainToInstance} from "class-transformer";
import {isWVMode} from "./lib/RNMessage/RNMessageWV";
import {DisconnectNotiFirebase, DisconnectNotiSignalRAsync} from "./App";
import {AuthContextType} from "./context/AuthContext";

export default class AuthData
{
    public user: UserDataSimple;
    public setting: UserSettingsAdminData;
    public login: LoginData;
    public company: CompanyData;
}

const AuthBypassPath: string[] = [
    URL_FRONT_LOGIN,
    URL_FRONT_ERROR
]


//현재 경로가 인증을 우회하는지 여부입니다
export function isAuthBypass(): boolean
{
    for (const bypass in AuthBypassPath) {
        if(bypass.startsWith(location.pathname)) return true;
    }

    return false;
}

/*
export function isLogin(): boolean
{
    Define.
}
*/

export async function getAuthData(location: Location<any>, navigate: NavigateFunction): Promise<AuthData | null>
{
    const SSOTokenKey = "sso_token";
    try
    {
        let header = {};
        const SSOToken = getParam(SSOTokenKey);
        if(!isEmptyString(SSOToken))
        {
            header = {...header, Authorization: `SSO ${SSOToken}`}
            //const response = await axios.post(`${URL_API_LOGIN_SSO}?LoginMode=${mode}`, form);
        }

        //const root = window.location.origin;
        const url = `${Define.URL_API_USER}?IsProfileImageThumb=true`;
        const response = await axios.get(url, {headers: header});

        if (response.data != null)
        {
            const data = plainToInstance<UserDataSimple, any>(UserDataSimple, response.data);
            if(!isEmptyString(SSOToken))
            {
                const params = new URLSearchParams(location.search);
                const params_new: string[] = [];
                params.forEach((value, name) =>
                {
                    if(name != SSOTokenKey) params_new.push(`${name}=${value}`)
                });

                navigate(`${location.pathname}?${params_new.joinEx("&")}`, {replace: true});

                //푸쉬 메세시 재등록 및 로그인 모드가 APP 이면 재 로그인
            }

            return {
                user: data,
                setting: (await UserSettingsAdminData.GetSettingsAsync(data.LoginID))!,
                login: LoginData.GetCurrentLoginData(),
                company: await CompanyData.GetCompany(),
            }
        }

        return null;
    }
    catch (ex)
    {
        if(ex instanceof AxiosError)
        {
            if (ex.code === 'ERR_NETWORK') alert("네트워크 연결을 확인해주세요");
            if (ex.response != null)
            {
                if (ex.response.status == 401) return null;
                else alert("로그인 확인에 실패하였습니다\n\n" + ex.message);
            }
        }

        console.error(ex);
        return null;
    }
}


export async function LogoutAsync(disconnectNoti?: boolean): Promise<boolean>
{
    try { await axios.get(URL_API_LOGOUT); }
    catch (e)
    {
        console.log("Failed to logout");
        console.log(e);

        if (isAxiosError(e) && e.code === "ERR_NETWORK")
        {
            if (!confirm("네트워크에 문제가 발생하여 로그아웃할 수 없습니다\n강제로 로그아웃 하시겠습니까?"))
            {
                return false;
            }
        }
    }

    try
    {
        if (isWVMode()) sendRNData({type: 'LOGOUT', data: null});

        localStorage.removeItem(STORAGE_KEY_LOGIN_APP_TOKEN);
        localStorage.removeItem(STORAGE_KEY_LOGIN_ID);
        localStorage.removeItem(STORAGE_KEY_COMPANY_ID);
    }
    catch (e)
    {
        console.log("Failed to remove LocalStorage");
        console.log(e);
    }

    if (disconnectNoti)
    {
        if (isWVMode()) await unregisterFCMToken();
        else
        {
            try { await DisconnectNotiSignalRAsync(); }
            catch (e)
            {
                console.log("Failed to disconnecting SignalR");
                console.log(e);
            }

            try { await DisconnectNotiFirebase(); }
            catch (e)
            {
                console.log("Failed to disconnecting Firebase");
                console.log(e);
            }
        }
    }

    return true;
}

export function removeAuth(auth:  AuthContextType, navigate?: NavigateFunction)
{
    auth.setAuth(null);
    if (navigate == null) location.replace(URL_FRONT_LOGIN);
    else navigate(URL_FRONT_LOGIN, {replace: true});
}

export async function FullLogoutAsync(auth:  AuthContextType, navigate?: NavigateFunction)
{
    if (await LogoutAsync(true)) removeAuth(auth, navigate);
    else
    {
        if (navigate == null) history.back();
        else navigate(-1);
    }
}