import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {COMPANY, isMockupMode, onBackClick, TITLE, URL_FRONT_LOGOUT, URL_FRONT_MAIN, URL_FRONT_USER} from "Define";
import {useAuth} from "context/AuthContext";
import {useTheme} from "context/ThemeContext";
import UserDataBase from "data/API/User/UserDataBase";
import {IconMoon, IconSun, IconUserOff, IconUserShield} from "@tabler/icons-react";
import AlertsUI from "./AlertsUI";
import UserImageViewer from "common/control/viewer/UserImageViewer";
import styles from './TopHeader.module.css';
import { IconX, IconMenu2, IconArrowLeft } from '@tabler/icons-react';
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import URL from "urijs";
import {isWVMode} from "../lib/RNMessage/RNMessageWV";
import {FullLogoutAsync} from "../Auth";

type TopHeaderProps = {
    onIsClick?:  React.Dispatch<React.SetStateAction<boolean>>,
    isClick?: boolean,
    backPreventURL?: string[],
}

function TopHeader(props: TopHeaderProps)
{
    const _auth = useAuth();
    const {auth, admin, setAdmin} = _auth;
    const {theme, setDarkMode} = useTheme();

    const getIsMobile = () => isWVMode() || window.innerWidth <= 1024;
    const [isMobile, setIsMobile] = useState(getIsMobile());
    const [isRootPath, setIsRootPath] = useState(getIsRootPath());
    const onWindowResize = useWindowResize();
    onWindowResize(() =>
    {
        setIsMobile(getIsMobile());
    });

    function getIsRootPath()
    {
        const URLAvailable = URL != undefined && URL.parse != undefined;
        if(props.backPreventURL != null)
        {
            const result = URLAvailable ? URL.parse(window.location.href).path : window.location.pathname;
            for (const path of props.backPreventURL)
            {
                if (path === result) return true;
            }
        }
        return false;
    }
    useEffect(() =>
    {
        window.addEventListener('popstate', () => setIsRootPath(getIsRootPath()));
    }, [])

    const Navigate = useNavigate();
    const _setAdmin = (admin: boolean) =>
    {
        if(setAdmin(admin))
            Navigate(URL_FRONT_MAIN, {replace: true});
    }
    const getAdminButton = (admin: boolean) =>
    {
        return  admin ?
            <button onClick={()=> _setAdmin(false)} className="nav-link px-0"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom" aria-label="일반 모드 전환"
                    data-bs-original-title="일반 모드 전환">
                <IconUserOff size={20} strokeWidth={1}/>
            </button>
            :
            <button onClick={()=> _setAdmin(true)} className="nav-link px-0"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom" aria-label="관리자모드 전환"
                    data-bs-original-title="관리자모드 전환">
                <IconUserShield size={20} strokeWidth={1}/>
            </button>
    }
    const getDarkModeButton = (darkMode: boolean) =>
    {
        return  darkMode ?
            <button onClick={() => setDarkMode(false)} className="nav-link px-0"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom" aria-label="다크모드 끔"
                    data-bs-original-title="다크모드 끔">
                <IconSun size={20} strokeWidth={1}/>
            </button>
            :
            <button onClick={() => setDarkMode(true)} className="nav-link px-0"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom" aria-label="다크모드 켬"
                    data-bs-original-title="다크모드 켬">
                <IconMoon size={20} strokeWidth={1}/>
            </button>
    }
    function onIsClick()
    {
        if(props.onIsClick != null) props.onIsClick(!props.isClick);
    }

    //https://min9nim.vercel.app/2020-06-09-dark-mode/ (Class 컴포넌트 시)
    return (auth == null ? <></> :
            <>
                <div className={`navbar navbar-expand-md navbar-light d-print-none ${styles.top_header}`}>
                    <div className="container-xl">
                        {isMobile && <div className="navbar-nav flex-row">
                            {isRootPath ? "" :
                                <div className={`nav-link ` + styles.nav_link} onClick={() => onBackClick(false)}>
                                    <IconArrowLeft/>
                                </div>
                            }
                            <div onClick={onIsClick} className={`nav-link ` + styles.nav_link_ham} data-bs-toggle="collapse"
                                 data-bs-target="#navbar-menu"
                                 aria-controls="navbar-menu" aria-expanded="false" aria-label="Toggle navigation">
                                {props.isClick ? <IconX stroke={2}/> : <IconMenu2 stroke={2}/>}
                            </div>

                        </div>}

                        <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
                            <Link to={URL_FRONT_MAIN} style={{fontSize: "20px"}} className={styles.top_logo}>
                                { isMockupMode() ? '(회사이름)' : auth.company.Name}
                            </Link>
                        </h1>
                        <div className="navbar-nav flex-row order-md-last">
                            <div className="flex-row d-md-flex navbar-nav">
                                {auth.user.IsAdmin ? <div className="d-none d-md-flex">{getAdminButton(admin)}</div> : ""}
                                { getDarkModeButton(theme.darkMode) }
                                {<div className="flex-row nav-link p-0"><AlertsUI/></div>}
                            </div>
                            <div className="nav-item dropdown">
                                <a className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown"
                                   aria-label="Open user menu" style={{cursor: 'pointer'}}>
                                <span className="avatar avatar-sm" style={{overflow: "hidden"}}>
                                    <UserImageViewer user={auth.user}/>
                                </span>
                                    <div className="d-none d-xl-block ps-2">
                                        <div>{auth.user.Name}</div>
                                        <div className="mt-1 small text-muted">{UserDataBase.getPosition(auth.user)}</div>
                                    </div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card"
                                     style={{width: "20rem"}}>
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="col">사용자 프로필</div>
                                            <div className="col-auto">
                                                {auth.user.IsAdmin ?
                                                        admin? <button type="button" className="badge bg-lime text-lime-fg" onClick={()=> _setAdmin(false)}>일반모드로 전환</button>:
                                                            <button type="button" className="badge bg-lime text-lime-fg" onClick={()=> _setAdmin(true)}>관리자로 전환</button>
                                                        : ""}
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-auto">
                                                <span className="avatar avatar-md">
                                                    <UserImageViewer user={auth.user}/>
                                                </span>
                                                </div>
                                                <div className="col">
                                                    <div>
                                                        <b>{auth.user.Name}</b>&nbsp;
                                                        <span className="text-muted">({auth.user.LoginID})</span><br/>
                                                        <span className="small text-muted">{UserDataBase.getPosition(auth.user)}</span>
                                                    </div>
                                                    <br/>
                                                    <div className="text-muted small">마지막 접속 아이피 : {auth.login.IP}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <Link to={URL_FRONT_USER} style={{float: "left"}}>마이페이지</Link>
                                            <Link to={URL_FRONT_LOGOUT} style={{float: "right"}}>로그아웃</Link>
                                            {/*<a onClick={() => FullLogoutAsync(_auth).then()} style={{float: "right"}}>로그아웃</a>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}

export default TopHeader;
