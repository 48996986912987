import React, {useEffect, useState} from "react";
import { useAuth } from "context/AuthContext";
import {useNavigate} from "react-router-dom";
import {FullLogoutAsync, removeAuth} from "../../Auth";
import {sleep} from "../../utils/HSUtils";

const LogoutPage: React.FC = () =>
{
    const auth= useAuth();
    const Navigate = useNavigate();

    const [enable, useEnable] = useState(false);

    useEffect(() =>
    {
        FullLogoutAsync(auth, Navigate).then();
        sleep(5000).then(() => useEnable(true));
    })

    //return <div>로그아웃 중입니다.<br/>잠시만 기다려 주십시오...</div>
    return (
        <div className="page">
            <div className="container-tight py-5">
                <div className="text-center mb-4">
                    <h1 className="h2">로그아웃 중입니다.<br/>잠시만 기다려 주십시오...</h1>
                    <p className="text-muted">5초가 지나도 자동으로 넘어가지 않으면<br/>아래의 버튼을 클릭해 주세요</p>
                </div>
                <div className="text-center">
                    <button disabled={!enable} onClick={() => removeAuth(auth, Navigate)} className="btn btn-primary">강제 로그아웃</button>
                </div>
            </div>
        </div>
    )
}

export default LogoutPage;
