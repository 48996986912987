import ModuleInfo, {
    AdminMenuKind,
    checkModuleKindMatch,
    checkModuleMenu,
    ModuleMenu,
    ModuleMenuCategory
} from "modules/ModuleInfo";
import ICON from "../resource/approval.png";
import {
    getListString,
    LIST_KIND_COMPLETE,
    LIST_KIND_DECISION,
    LIST_KIND_EXEC,
    LIST_KIND_EXEC_ALL,
    LIST_KIND_HOLD,
    LIST_KIND_PROGRESS,
    LIST_KIND_PROGRESS_ALL,
    LIST_KIND_PROGRESS_ME,
    LIST_KIND_REF,
    LIST_KIND_REJECT,
    LIST_KIND_REPORT,
    LIST_KIND_TEMP, LIST_MY_CLASS,
    MENU_HANDOVER,
    MENU_HANDOVER_DOC,
    PATH_APPROVAL,
    PATH_APPROVAL_LIST,
    PATH_APPROVAL_SETTING,
    PATH_APPROVAL_WRITE,
    PATH_APPROVAL_WRITE_FORMAT,
    PATH_APPROVAL_WRITE_TEMP
} from "../Define";

import iconDefault from "../resource/menu/menu_default.png";
import iconDefaultDark from "../resource/menu/menu_default_dark.png";
import iconWrite from "../resource/menu/menu_write.png";
import iconWriteDark from "../resource/menu/menu_write_dark.png";

import {
    checkGetValue,
    dateFormat,
    isEmptyString,
    isNullorWhiteSpace,
    parseDate,
    PathMakerMulti
} from "@hskernel/hs-utils";
import {IconFileDescription} from "@tabler/icons-react";
import React from "react";
import AlertData from "data/API/Alert/AlertData";
import ApprovalAlertData from "../data/alert/ApprovalAlertData";
import {DocumentViewOpen} from "../page/document/DocumentViewPage";
import {
    DateRangeData, DEFAULT_SEARCH_DATE_FULL,
    SearchWithDateRangeFullData
} from "../../../common/control/SearchWithDateRangeControl";
import {ModuleMenuSubData} from "../../../data/API/Module/Menu/ModuleMenuData";
import {sleep} from "@hskernel/hs-utils-html5";
import ApprovalDocumentListData, {
    SEARCH_KIND_DOC,
    SEARCH_KIND_TITLE,
    SEARCH_KIND_WRITER
} from "../data/ApprovalDocumentListData";
import qs from "qs";
import {makeSearchOptionParamKey, SearchOptionProps} from "../../../common/control/SearchControl";
import {SelectOptionData} from "../../../common/component/SelectOption";

const renderIcon = (darkMode: boolean) => darkMode ? iconWriteDark : iconWrite;

/**
 * 메뉴 카운트 불러올 데이터
 */
type ListRequestData = { Request: string };

export const DEFAULT_MODULE_PATH = (admin: boolean) =>  PathMakerMulti(PATH_APPROVAL, PATH_APPROVAL_LIST, LIST_KIND_REPORT); //PathMakerMulti(PATH_APPROVAL, PATH_APPROVAL_WRITE, admin ? LIST_KIND_REPORT : PATH_APPROVAL_WRITE_FORMAT);
export const ApprovalModuleID = PATH_APPROVAL;
const Info: ModuleInfo =
{
    id: ApprovalModuleID,
    name: "Approval",
    title: "전자결재",
    onRenderIcon: (darkMode, isSmall) => <IconFileDescription/>,
    defaultPath: (admin) => DEFAULT_MODULE_PATH(admin),
    //renderMulti: false,
    onRenderInit: onRenderInit,
    onNotiReceive: onNotiReceived,
    onClick: onClick,
    //admin: process.env.NODE_ENV == "development" ? AdminMenuKind.All : AdminMenuKind.UserOnly,//권한 기능 개발 완료시까지 관리자 비활성화
    category: [
        {
            id: 'write',
            title: "결재문서작성",
            path: PATH_APPROVAL_WRITE,
            open: true,
            admin: AdminMenuKind.UserOnly,
            menu: [
                {
                    id: 'format',
                    title: "결재문서양식",
                    path: PATH_APPROVAL_WRITE_FORMAT,
                    popup: false,
                },
                {
                    id: 'temp',
                    title: getListString(LIST_KIND_TEMP),
                    path: PATH_APPROVAL_WRITE_TEMP,
                    /**
                     * @type ListRequestData
                     */
                    data: { Request: LIST_KIND_TEMP },
                    popup: false,
                    onRender: onMenuRender
                }
            ]
        },
        {
            id: 'list',
            title: "결재문서",
            path: PATH_APPROVAL_LIST,
            open: true,
            menu: [
                {
                    id: 'report',
                    title: getListString(LIST_KIND_REPORT),
                    path: LIST_KIND_REPORT,
                    popup: false,
                    /**
                     * @type ListRequestData
                     */
                    data: { Request: LIST_KIND_REPORT },
                    shortcut: () => 'show',
                    onRenderIcon: (darkMode: boolean) => darkMode ? iconDefaultDark : iconDefault,
                    onRender: onMenuRender
                },
                {
                    id: 'progress',
                    title: getListString(LIST_KIND_PROGRESS),
                    path: LIST_KIND_PROGRESS,
                    popup: false,
                    /**
                     * @type ListRequestData
                     */
                    data: { Request: LIST_KIND_PROGRESS },
                    shortcut: () => 'show',
                    onRenderIcon: (darkMode: boolean) => darkMode ? iconDefaultDark : iconDefault,
                    onRender: onMenuRender
                },
                {
                    id: 'progress_all',
                    title: getListString(LIST_KIND_PROGRESS_ALL),
                    path: LIST_KIND_PROGRESS_ALL,
                    popup: false,
                    hide: true,
                    admin: AdminMenuKind.UserOnly,
                    /**
                     * @type ListRequestData
                     */
                    data: { Request: LIST_KIND_PROGRESS_ALL },
                    shortcut: () => 'none',
                    onRenderIcon: (darkMode: boolean) => darkMode ? iconDefaultDark : iconDefault,
                    onRender: onMenuRender
                },
                {
                    id: 'progress_me',
                    title: getListString(LIST_KIND_PROGRESS_ME),
                    path: LIST_KIND_PROGRESS_ME,
                    popup: false,
                    hide: true,
                    admin: AdminMenuKind.UserOnly,
                    /**
                     * @type ListRequestData
                     */
                    data: { Request: LIST_KIND_PROGRESS_ME },
                    shortcut: () => 'none',
                    onRenderIcon: (darkMode: boolean) => darkMode ? iconDefaultDark : iconDefault,
                    onRender: onMenuRender
                },
                {
                    id: 'decision',
                    title: getListString(LIST_KIND_DECISION),
                    path: LIST_KIND_DECISION,
                    popup: false,
                    admin: AdminMenuKind.UserOnly,
                    /**
                     * @type ListRequestData
                     */
                    data: { Request: LIST_KIND_DECISION },
                    shortcut: (isAdminMode) => isAdminMode ? 'none' : 'auto',
                    onRenderIcon: renderIcon,
                    onRender: onMenuRender
                },
                {
                    id: 'complete',
                    title: getListString(LIST_KIND_COMPLETE),
                    path: LIST_KIND_COMPLETE,
                    popup: false,
                    /**
                     * @type ListRequestData
                     */
                    data: { Request: LIST_KIND_COMPLETE },
                    shortcut: (isAdminMode) => isAdminMode ? 'auto' : 'none',
                    onRenderIcon:renderIcon,
                    onRender: onMenuRender
                },
                {
                    id: 'reject',
                    title: getListString(LIST_KIND_REJECT),
                    path: LIST_KIND_REJECT,
                    popup: false,
                    /**
                     * @type ListRequestData
                     */
                    data: { Request: LIST_KIND_REJECT },
                    shortcut: () => 'show',
                    onRenderIcon: renderIcon,
                    onRender: onMenuRender
                },
                {
                    id: 'hold',
                    title: getListString(LIST_KIND_HOLD),
                    path: LIST_KIND_HOLD,
                    popup: false,
                    /**
                     * @type ListRequestData
                     */
                    data: { Request: LIST_KIND_HOLD },
                    shortcut: (isAdminMode) => isAdminMode ? 'show' : 'hide',
                    onRenderIcon: renderIcon,
                    onRender: onMenuRender
                },
                {
                    id: 'ref',
                    title: getListString(LIST_KIND_REF),
                    path: LIST_KIND_REF,
                    popup: false,
                    admin: AdminMenuKind.UserOnly,
                    /**
                     * @type ListRequestData
                     */
                    data: { Request: LIST_KIND_REF },
                    shortcut: (isAdminMode) => isAdminMode ? 'none' : 'hide',
                    onRenderIcon: renderIcon,
                    onRender: onMenuRender
                },
                {
                    id: 'exec',
                    title: getListString(LIST_KIND_EXEC),
                    path: LIST_KIND_EXEC,
                    popup: false,
                    admin: AdminMenuKind.UserOnly,
                    /**
                     * @type ListRequestData
                     */
                    data: { Request: LIST_KIND_EXEC },
                    shortcut: (isAdminMode) => isAdminMode ? 'none' : 'hide',
                    onRenderIcon: renderIcon,
                    onRender: onMenuRender
                },
                {
                    id: 'exec_all',
                    title: getListString(LIST_KIND_EXEC_ALL),
                    path: LIST_KIND_EXEC_ALL,
                    popup: false,
                    admin: AdminMenuKind.UserOnly,
                    /**
                     * @type ListRequestData
                     */
                    data: { Request: LIST_KIND_EXEC_ALL },
                    shortcut: (isAdminMode) => isAdminMode ? 'none' : 'hide',
                    onRenderIcon: renderIcon,
                    onRender: onMenuRender
                },
                {
                    id: 'official_doc',
                    title: "공문발송문서 (미구현)",
                    path: "8",
                    popup: false,
                    hide: true
                }
            ]
        },
        {
            id: 'setting',
            title: "결재설정",
            path: PATH_APPROVAL_SETTING,
            open: true,
            admin: AdminMenuKind.UserOnly,
            menu: [
                {
                    id: 'line',
                    title: "결재라인설정",
                    path: "line",
                    admin: AdminMenuKind.UserOnly,
                    popup: false,
                },
                {
                    id: 'instead',
                    title: "대결자설정",
                    path: "instead",
                    admin: AdminMenuKind.UserOnly,
                    popup: false,
                },
                {
                    id: 'sub',
                    title: "하위결재함관리",
                    path: "2",
                    popup: false,
                    admin: AdminMenuKind.AdminOnly,
                    hide: true
                }
            ]
        },
        {
            id: 'handover',
            title: "인수인계",
            path: MENU_HANDOVER,
            open: true,
            hide: true,
            menu: [
                {
                    id: 'manage',
                    title: "인수인계관리",
                    path: "",
                    popup: false,
                },
                {
                    id: 'doc',
                    title: "인수인계문서",
                    path: MENU_HANDOVER_DOC,
                    hide: true,
                    popup: false,
                }
            ]
        }
    ],
    onDataReceive: (approvalAlertData: string) =>
    {
        const data: ApprovalAlertData = JSON.parse(approvalAlertData);
        console.log(`RECEIVED APPROVAL DATA!!: ${JSON.stringify(data)}`);
        if (data.ReadOnly) return false;
        else
        {
            DocumentViewOpen(data.DocumentID);
            return true;
        }
    }
}

export default Info;

export const SEARCH_CONTROL_NAME: string | undefined = undefined;
export function GET_DEFAULT_DATE(): DateRangeData {
    const now = new Date();
    return {
        start: now.addDays(-365),
        end: now,
    }
}

export const getSearchOptionInit = (isAdminMode: boolean, kind: string) =>
{
    const arr: SearchOptionProps[] = [{
        autoSearch: false,
        name: 'keywordKind',
        options: [
            new SelectOptionData("제목", SEARCH_KIND_TITLE),
            new SelectOptionData("기안자", SEARCH_KIND_WRITER),
            new SelectOptionData("문서번호", SEARCH_KIND_DOC),
        ]
    }];

    //내 클래스에서는 내것만 표시되니 지우기
    if(!isAdminMode && kind == LIST_MY_CLASS)
    {
        arr[0].options.splice(1, 1);
    }

    return arr;
}
export const getSearchOptionValueInit = (isAdminMode: boolean, kind: string) => [
    getSearchOptionInit(isAdminMode, kind)[0].options[0].Value,
]

const getQuery = () => qs.parse(location.search, { ignoreQueryPrefix: true });

export const DEFAULT_DOCUMENT_SEARCH_PARAM = (options?: SearchOptionProps[], ignoreURL = false) => SearchDocumentParam.fromSearchData(DEFAULT_SEARCH_DATE_FULL(SEARCH_CONTROL_NAME, GET_DEFAULT_DATE(), options, undefined, ignoreURL));
export class SearchDocumentParam
{
    public keyword: string | null = null;
    public keywordKind: string | null = null;
    public start: Date | null = null;
    public end: Date | null = null;

    constructor(ignoreURL = false, keyword?: string | null, keywordKind?: string | null, start?: Date | null, end?: Date | null)
    {
        if (keyword) this.keyword = keyword;
        if (keywordKind) this.keywordKind = keywordKind;

        const defaultDate = GET_DEFAULT_DATE();
        this.start = checkGetValue(start, defaultDate.start, null);
        this.end = checkGetValue(end, defaultDate.end, null);

        if (!ignoreURL)
        {
            const query = getQuery();
            const _keyword = query['keyword'];
            const _keywordKind = query['keywordKind'];
            const _start = query['start'];
            const _end = query['end'];
            if (_keyword != null) this.keyword = _keyword.toString();
            if (_keywordKind != null) this.keywordKind = _keywordKind.toString();
            if (_start != null)
            {
                const _startDate = parseDate(_start.toString());
                if (_startDate != null) this.start = _startDate;
            }
            if (_end != null)
            {
                const _endDate = parseDate(_end.toString());
                if (_endDate != null) this.end = _endDate;
            }
        }
    }

    public setKeyword(keyword: string | null){ this.keyword = keyword; return this; }
    public setKeywordKind(keywordKind: string | null){ this.keywordKind = keywordKind; return this; }
    public setStart(start: Date | null){ this.start = start; return this; }
    public setEnd(end: Date | null){ this.end = end; return this; }

    public getAPIParams()
    {
        const params: string[] = [];
        if(!isNullorWhiteSpace(this.keyword)) params.push(`Keyword=${this.keyword}`);
        if(!isNullorWhiteSpace(this.keywordKind)) params.push(`KeywordKind=${this.keywordKind}`);
        if(this.start != null) params.push(`DateStart=${dateFormat(this.start.addDays(-1), 'yyyy-mm-dd')}`);
        if(this.end != null) params.push(`DateEnd=${dateFormat(this.end.addDays(1), 'yyyy-mm-dd')}`);
        return params;
    }

    public getURLParam()
    {
        const query = getQuery();
        query['keyword'] = isNullorWhiteSpace(this.keyword) ? undefined : this.keyword!;
        query['keywordKind'] = isNullorWhiteSpace(this.keywordKind) ? undefined : this.keywordKind!;
        return query;
    }

    public clone = () => new SearchDocumentParam(true, this.keyword, this.keywordKind, this.start, this.end);

    public static fromSearchData(search: SearchWithDateRangeFullData)
    {
        //console.log(new SearchDocumentParam(true, search.keyword, search.options[0]?.value, search.start, search.end))
        return new SearchDocumentParam(true, search.keyword, search.options[0]?.value, search.start, search.end);
    }
}


let doRenderAsync: () => Promise<void> | undefined;
const totalDoc = new Map<string, number>();
let MenuCountParam: SearchDocumentParam | undefined;
export const getMenuCountParam = () => MenuCountParam;
export const setMenuCountParam = (data: SearchDocumentParam, clearKeyword = true) =>
{
    MenuCountParam = clearKeyword ? new SearchDocumentParam(true, null, null, data.start ?? null, data.end ?? null) : data.clone();
    if (doRenderAsync != null) doRenderAsync();
};

let isLoading = false;
async function onRenderInit(admin: boolean, menuRoot: ModuleMenuSubData | undefined, _doRerenderAsync: () => Promise<void>, forceRefresh?: boolean)
{
    console.log("onRenderInit!!!!")
    doRenderAsync = _doRerenderAsync;
    if (menuRoot != null)
    {
        const requests: string[] = [];
        const getRequest = (menuCatModuleData: ModuleMenuSubData | undefined | null, _menucat: ModuleMenuCategory | ModuleMenu) =>
        {
            if (checkModuleKindMatch(admin, _menucat.admin) && menuCatModuleData != null)
            {
                const _data = menuCatModuleData[_menucat.id];
                if (_data != null)
                {
                    if (_data.Display == "Show" && _menucat.data != null)
                    {
                        const MenuData = _menucat.data as ListRequestData;
                        if (MenuData != null && MenuData.Request != null) requests.push(MenuData.Request);
                    }

                    if (_menucat.menu != null) _menucat.menu.forEach(menu => getRequest(_data.Sub, menu));
                }
            }
        }

        Info.category.forEach(category => getRequest(menuRoot, category));
        //console.log(requests);

        isLoading = true;

        totalDoc.clear();
        if (MenuCountParam != null)
        {
            const totalData = await ApprovalDocumentListData.GetCountTotalAsync(requests, MenuCountParam, null, admin);
            if (totalData != null) Object.keys(totalData).forEach(key => totalDoc.set(key, totalData[key]));
        }
        isLoading = false;
    }
}

/**
 * 메뉴가 그려진 때 발생합니다
 * @param menu
 * @param isShortcut true 면 모바일 여부 입니다
 * @param admin
 */
async function onMenuRender(menu: ModuleMenu, isShortcut: boolean, admin: boolean)
{
    while(isLoading) await sleep(100);

    if (!isShortcut && MenuCountParam != null)
    {
        const total = totalDoc.get(menu.path) ?? await ApprovalDocumentListData.GetCountAsync(menu.path, MenuCountParam) ?? 0;
        menu.title = `${getListString(menu.path)} (${total})`;
    }
    else menu.title = getListString(menu.path);

    menu.placeholder = false;
    return menu;
}

function onNotiReceived(alert: AlertData)
{
    if (doRenderAsync != null) doRenderAsync();
    return true;
}

export let LastMenu: string | null = null;

function onClick(category: ModuleMenuCategory, menu?: ModuleMenu | undefined)
{
    if (menu != null)
    {
        if (LastMenu == null || menu.id != LastMenu) LastMenu = menu.id;
        else
        {
            onDocumentListRefresh();
            //true 면 사이드메뉴 갯수 새로고침 안함
            //return true;
        }
    }
    return false;
}

type ApprovalDocumentRefreshCallbackType = () => void;
const _ApprovalDocumentRefresh: ApprovalDocumentRefreshCallbackType[] = [];
function onDocumentListRefresh()
{
    if (_ApprovalDocumentRefresh.length > 0) _ApprovalDocumentRefresh.map((callback) => callback());
}
export function AddApprovalRefreshCallback(onRefresh: ApprovalDocumentRefreshCallbackType)
{
    for(let i = 0; i < _ApprovalDocumentRefresh.length; i++)
    {
        if(_ApprovalDocumentRefresh[i] === onRefresh) return;
    }
    _ApprovalDocumentRefresh.push(onRefresh);
}
export function RemoveApprovalRefreshCallback(onRefresh: ApprovalDocumentRefreshCallbackType)
{
    for(let i = _ApprovalDocumentRefresh.length - 1; i >= 0; i--)
        if(_ApprovalDocumentRefresh[i] == onRefresh) _ApprovalDocumentRefresh.remove(i);
}